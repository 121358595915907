<template>
<div class="helpBox">
    <video controls="controls" class="help-video">
        <source src="https://platform.tgju.org/files/videos/support-e/mp4/2-2.mp4" type="video/mp4"></video>
    <input class="faq-search" type="text" placeholder="جستجو در راهنما ..." v-model="searchQuery">
    <section class="faq-accordion" itemscope="" itemprop="mainEntity" itemtype="https://schema.org/FAQPage">
        <div v-for="faq in filteredList" :key="faq.id" :class="'card ' + (faq.id == activeFaq ? 'active' : '') " itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
            <h3 class="title-faq" @click="setActive(faq.id)" itemprop="name"><i class="fa fa-plus-square-o"></i> {{faq.title}}</h3>
            <div class="answer-faq" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <div itemprop="text" v-html="faq.answer"></div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
// راهنما
export default {
    name: 'HelpsDashboards',
    components: {},
    props: ['data'],
    data: function () {
        return {
            activeFaq: 0,
            searchQuery: '',
            Faqs: []
        }
    },
    computed: {
        // فیتلر سازی لیست های وظایف
        filteredList() {
            return this.Faqs.filter(Faq => {
                return Faq.title.toLowerCase().includes(this.searchQuery.toLowerCase())
            })
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData(){
            $.getJSON('https://api.tgju.org/v1/support/list/95358/true', (json_data) =>{
                var list = [];
                json_data.response.items.forEach((item) => {
                    list.push({
                        id: item.id,
                        title: item.title,
                        answer: item.answer,
                        show: false
                    });
                });
                this.Faqs = this.reverseArr(list);
            });
        },
        reverseArr(input) {
            var ret = new Array;
            for(var i = input.length-1; i >= 0; i--) {
                ret.push(input[i]);
            }
            return ret;
        },
        setActive(id) {
            if (id == this.activeFaq) {
                this.activeFaq = 0;
            } else {
                this.activeFaq = id;
            }
        },
    },
}
</script>
 